import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import { ISummaryNumberDetails, ISummaryNumberCardColors } from "shared/Modules/UserCards/userCardsTypes"
import Card from "shared/Components/Card/Card"

type IClassesProps = {
    summaryNumber?: ISummaryNumberCardColors
}

const useStyles = makeStyles(() =>
    createStyles({
        summaryCardContent: ({ summaryNumber }: IClassesProps) => ({
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: summaryNumber?.background || "#fff",
        }),
        summaryCardTextRowWrapper: {
            height: "2.75rem",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        },
        summaryCardHeader: ({ summaryNumber }: IClassesProps) => ({
            fontSize: "0.75rem",
            fontWeight: 600,
            color: summaryNumber?.header || "#000000FF",
            verticalAlign: "middle",
        }),
        summaryCardValue: ({ summaryNumber }: IClassesProps) => ({
            fontSize: "3.5rem",
            margin: "4px 0",
            color: summaryNumber?.value || "#000000FF",
        }),
        summaryCardUnit: ({ summaryNumber }: IClassesProps) => ({
            fontSize: "1rem",
            color: summaryNumber?.unit || summaryNumber?.value || "#000000FF",
            marginLeft: "0.25rem",
        }),
        summaryCardFooter: ({ summaryNumber }: IClassesProps) => ({
            fontSize: "0.75rem",
            color: summaryNumber?.footer || "#969696FF",
        }),
    })
)

type SummaryNumberCardProps = ISummaryNumberDetails

export function SummaryNumberCard(props: SummaryNumberCardProps) {
    const { header, value, unit, footer } = props.content
    const classes = useStyles({ summaryNumber: props.colors ?? {} })

    return (
        <Card width="100%">
            <div className={classes.summaryCardContent}>
                <div className={classes.summaryCardTextRowWrapper}>
                    <Typography variant="body1" className={classes.summaryCardHeader}>
                        {header}
                    </Typography>
                </div>
                <Typography variant="body2" className={classes.summaryCardValue}>
                    {value}
                    {unit && <span className={classes.summaryCardUnit}>{unit}</span>}
                </Typography>
                <div className={classes.summaryCardTextRowWrapper}>
                    <Typography variant="body1" className={classes.summaryCardFooter}>
                        {footer}
                    </Typography>
                </div>
            </div>
        </Card>
    )
}
