import React from "react"
import clsx from "clsx"
import getFromPath from "lodash/get"
import { CardMedia, CardContent, makeStyles, Grid } from "@material-ui/core"
import { IMonetaryAmount } from "shared/Types/appTypes"
import Card from "shared/Components/Card/Card"
import { Roboto } from "shared/theme"
import { HomeCardDescription } from "shared/Components/Skeleton/HomeCardDescription"
import { HomeCardTitle } from "shared/Components/Skeleton/HomeCardTitle"

const useStyles = makeStyles((theme) => ({
    productName: {
        float: "left",
        display: "block",
        marginRight: theme.spacing(1),
        maxWidth: "80%",
    },
    cardTitleWrap: {
        flexDirection: "column",
        gap: "0.25rem",
    },
    productCardContent: {
        padding: "0.5rem 0",
    },
    updateProductCardContent: {
        "&.MuiCardContent-root:last-child": {
            paddingBottom: "0.25rem",
        },
    },
    relative: {
        position: "relative",
    },
    description: {
        clear: "both",
        margin: 0,
        overflow: "hidden",
        whiteSpace: "nowrap",
        width: "100%",
    },
}))

interface IProps {
    resource: any
    onClick: (resource: any) => void
    resourceTitleProp: string
    resourceImageProp: string
    headerTagTextProp: string
    resourcePriceProp?: string
    resourceSubTitleProp?: string
    headerTagColorProp?: string
    updateProductCardUi?: boolean
}

export default function ResourceCard(props: IProps) {
    const classes = useStyles()

    function headerTag() {
        if (!getFromPath(props.resource, props.headerTagTextProp)) {
            return <div />
        }

        return (
            <p
                style={{
                    position: "absolute",
                    top: 0,
                    left: 15,
                    color: "#FFFFFF",
                    fontFamily: Roboto,
                    fontSize: "0.875rem", // = 14px @ std
                    letterSpacing: -0.25,
                    borderRadius: 11,
                    backgroundColor:
                        props.headerTagColorProp && getFromPath(props.resource, props.headerTagColorProp)
                            ? getFromPath(props.resource, props.headerTagColorProp)
                            : "rgba(0,0,0,0.31)",
                    padding: "4px 10px",
                }}
            >
                {getFromPath(props.resource, props.headerTagTextProp)}
            </p>
        )
    }

    function pricing(resource: any) {
        if (!props.resourcePriceProp || !getFromPath(props.resource, props.resourcePriceProp)) {
            return <div />
        }

        return (
            <HomeCardDescription>
                {(getFromPath(props.resource, props.resourcePriceProp) as IMonetaryAmount).formatted}
            </HomeCardDescription>
        )
    }

    return (
        <div onClick={() => props.onClick(props.resource)} style={{ width: "100%" }}>
            <Card height="100%" width="100%" boxShadow="none" borderRadius=".25rem">
                <div className={classes.relative}>
                    <CardMedia
                        component="img"
                        alt={getFromPath(props.resource, props.resourceTitleProp)}
                        height="172"
                        style={{ borderRadius: ".25rem" }}
                        image={getFromPath(
                            props.resource,
                            props.resourceImageProp,
                            "https://facilitynet.dk/remote/burgers.jpg"
                        )}
                        title={getFromPath(props.resource, props.resourceTitleProp)}
                    />
                    {headerTag()}
                </div>
                <CardContent
                    className={clsx(
                        classes.productCardContent,
                        !!props.updateProductCardUi && classes.updateProductCardContent
                    )}
                >
                    <Grid container justifyContent="space-between" wrap="nowrap" className={classes.cardTitleWrap}>
                        <HomeCardTitle className={classes.productName}>
                            {getFromPath(props.resource, props.resourceTitleProp)}
                        </HomeCardTitle>

                        {props.resourceSubTitleProp && getFromPath(props.resource, props.resourceSubTitleProp) ? (
                            <HomeCardDescription className={classes.description}>
                                {getFromPath(props.resource, props.resourceSubTitleProp)}
                            </HomeCardDescription>
                        ) : (
                            ""
                        )}
                    </Grid>
                    {pricing(props.resource)}
                </CardContent>
            </Card>
        </div>
    )
}
