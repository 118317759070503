import { isCreatePassword, isRequiresActivationCode, isRequiresSelectCompany, isRequiresSingleSignOn, LoginStatus, LoginStore } from "./loginTypes"

export function selectApiToken(state: LoginStore) {
  return state.login.loginStatus === LoginStatus.LOGGED_IN ? state.login.authentication.token : null
}

export function selectUserUid(state: LoginStore) {
  return state.login.loginStatus === LoginStatus.LOGGED_IN ? state.login.authentication.userUid : null
}

export function selectUserId(state: LoginStore) {
  return state.login.loginStatus === LoginStatus.LOGGED_IN ? state.login.authentication.userId : null
}

export function selectAccountDetails(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING ? state.login.account : undefined
}

export function selectIsLoggedIn(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.LOGGED_IN
}

export function selectCurrentLoginFlow({ login }: LoginStore) {
    return login.loginStatus === LoginStatus.AUTHENTICATING ? login.flow.current : undefined
}

export function selectCorrectLoginFlow({ login }: LoginStore) {
    return login.loginStatus === LoginStatus.AUTHENTICATING ? login.flow.correct : undefined
}

export function selectIsLoading(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING && state.login.loading
}

export function selectUsername(state: LoginStore) {
    if (state.login.loginStatus === LoginStatus.AUTHENTICATING && state.login.companyChoice?.user?.username) {
        return state.login.companyChoice.user.username
    }

    return state.login.username
}

export function selectCompanyChoice(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING ? state.login.companyChoice : undefined
}

export function selectAuthenticationResultMessage(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING ?
        state.login.response?.authenticationResult.message : undefined
}

export function selectActivationCodeCommunicationChannel(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING &&
        state.login.response &&
        isRequiresActivationCode(state.login.response)
        ? state.login.response.communicationChannel
        : undefined
}

export function selectAuthenticationResultOneTimeToken(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING &&
        state.login.response &&
        isCreatePassword(state.login.response)
        ? state.login.response.authentication.token
        : undefined
}

export function selectSingleSignOnOrganization(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING &&
        state.login.response &&
        isRequiresSingleSignOn(state.login.response) ?
        state.login.response.singleSignOn.organization :
        undefined
}

export function selectSingleSignOnUrl(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING &&
        state.login.response &&
        isRequiresSingleSignOn(state.login.response) ?
        state.login.response.singleSignOn.url :
        undefined
}

export function selectIsRequiresSelectCompany(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING &&
        state.login.response && isRequiresSelectCompany(state.login.response)
}

export function selectCompanyChoices(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING &&
        state.login.response &&
        isRequiresSelectCompany(state.login.response) ?
        state.login.response.selectCompany.companies :
        undefined
}

export function selectCompanyReason(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING &&
        state.login.response &&
        isRequiresSelectCompany(state.login.response) ?
        state.login.response.selectCompany.reason :
        undefined
}

export function selectCompanyDuplicateValue(state: LoginStore) {
    return state.login.loginStatus === LoginStatus.AUTHENTICATING &&
        state.login.response &&
        isRequiresSelectCompany(state.login.response) ?
        state.login.response.selectCompany.duplicateValue :
        undefined
}
