import React, { useEffect, useRef, useState } from "react"
import clsx from "clsx"
import { useSwipeable } from "react-swipeable"
import { Player } from "@lottiefiles/react-lottie-player"
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux"
import { Box, createStyles, Drawer, Grid, makeStyles, Tab, Tabs, Theme, Typography } from "@material-ui/core"
import { closeWeekMenuModal, ICloseWeekMenuModal } from "../Actions/weekMenuModalActions"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import NoDataAnim from "shared/assets/lottie/week-menu-no-data-lottie-anim.json"
import { useDateTime } from "shared/Modules/Localization/useDateTime"
import ProductCard from "mobile/Screens/Home/components/ProductCard"
import { IMenu, IMenuProduct } from "shared/Types/appTypes"
import ModalHeaderContent from "./ModalHeaderContent"
import { IStoreState } from "../Reducers/rootReducer"
import { Roboto } from "shared/theme"

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    weeksMenu: IMenu[] | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideWeeksMenuModal: () => ICloseWeekMenuModal
}

type IProps = IOwnProps & IStateProps & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ weekMenuModal, weeksMenu }) => ({
    isDrawerOpen: weekMenuModal.open,
    weeksMenu: "menues" in weeksMenu ? weeksMenu.menues : [],
})
const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideWeeksMenuModal: () => dispatch(closeWeekMenuModal()),
})

const headerHeight = 50
const headerWithTabsHeight = 110

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: "100%",
            overflow: "auto",
            width: "100vw",
            background: "#F1F5F9",
        },
        contentWrap: {
            padding: "2rem 1rem 3rem 1rem",
            marginTop: headerHeight,
        },
        contentWrapUpdate: {
            marginTop: headerWithTabsHeight,
            minHeight: `calc(90% - ${headerWithTabsHeight}px)`,
        },
        root: {
            position: "fixed",
        },
        noWeeksMenu: {
            color: theme.palette.text.primary,
            fontFamily: Roboto,
            textAlign: "center",
            marginBottom: 0,
            fontWeight: 500,
        },
        noWeeksMenuSubtitle: {
            color: "#00000099",
            fontFamily: Roboto,
            textAlign: "center",
            fontSize: ".875rem",
        },
        tabs: {
            width: "100%",
            background: theme.palette.common.white,
            padding: "0.5rem 0",
        },
        tab: {
            paddingTop: 9,
            paddingBottom: 9,
            paddingLeft: 16,
            paddingRight: 16,
            borderRadius: 45,
            marginLeft: "0.625rem",
            minHeight: "auto",
            border: "none",

            "&.Mui-selected": {
                color: theme.palette.common.white,
                background: theme.palette.common.black,
                fontWeight: "bold",
            },
            "&:last-child": {
                marginRight: "0.625rem",
            },
        },
        tabContainer: {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
        },
        tabPanel: {
            transition: "all 0.5s ease-in-out",
            opacity: 1,
        },
        tabPanelHidden: {
            opacity: 0,
        },
        noDataContainer: {
            padding: "2rem 1rem 1rem 1rem",
            flexDirection: "column",
            background: theme.palette.common.white,
            borderRadius: ".75rem",
        },
        media: {
            height: "17rem",
        },
    })
)

interface TabPanelProps {
    children?: React.ReactNode
    dir?: string
    index: number
    value: number
}

function TabPanel(props: TabPanelProps) {
    const classes = useStyles()
    const { children, value, index, ...other } = props

    return (
        <div
            role="tabpanel"
            id={`full-width-tabpanel-${index}`}
            aria-labelledby={`full-width-tab-${index}`}
            {...other}
            className={clsx(classes.tabPanel, value !== index && classes.tabPanelHidden)}
        >
            {value === index && <>{children}</>}
        </div>
    )
}

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { hideWeeksMenuModal, weeksMenu } = props
    const dateTimeFactory = useDateTime()
    const classes = useStyles()
    const [activeTab, setActiveTab] = useState(0)
    const tabsRef = useRef<HTMLDivElement>(null)
    const isWeekMenu = weeksMenu && weeksMenu.length > 0

    const scrollToCenter = (index: number) => {
        if (tabsRef.current) {
            const tabsContainer = tabsRef.current.querySelector(".MuiTabs-scroller")
            const tabs = tabsRef.current.querySelectorAll(".MuiTab-root")

            if (tabsContainer && tabs[index]) {
                const activeTab = tabs[index] as HTMLElement // Get the active table
                const tabLeftOffset = activeTab.offsetLeft // Position of the active tab relative to the parent container
                const tabWidth = activeTab.offsetWidth // Width of the active tab
                const containerWidth = tabsContainer.clientWidth // Width of the tab container

                // Calculate where to scroll so that the active tab is in the center
                const scrollPosition = tabLeftOffset - containerWidth / 2 + tabWidth / 2

                tabsContainer.scrollLeft = scrollPosition
            }
        }
    }

    useEffect(() => {
        scrollToCenter(activeTab)
    }, [activeTab])

    const handleSwipeLeft = () => {
        if (weeksMenu && activeTab < weeksMenu.length - 1) {
            setActiveTab((prev) => prev + 1)
        }
    }

    const handleSwipeRight = () => {
        if (weeksMenu && activeTab > 0) {
            setActiveTab((prev) => prev - 1)
        }
    }

    const swipeHandlers = useSwipeable({
        onSwipedLeft: handleSwipeLeft,
        onSwipedRight: handleSwipeRight,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true,
    })

    const weekMenuElements = () => {
        if (weeksMenu && weeksMenu.length > 0) {
            return weeksMenu.map((menu: IMenu, menuKey: number) => (
                <TabPanel value={activeTab} index={menuKey} key={menuKey}>
                    <Box className={classes.tabContainer} hidden={activeTab !== menuKey}>
                        {menu.products.map((product: IMenuProduct, index: number) => (
                            <ProductCard
                                key={index}
                                resource={product}
                                resourceTitleProp="subject"
                                resourceImageProp="imageUrl"
                                headerTagTextProp="headerTag"
                                headerTagColorProp="headerTagColor"
                                resourcePriceProp="price"
                            />
                        ))}
                    </Box>
                </TabPanel>
            ))
        }

        return (
            <Grid container className={classes.noDataContainer}>
                <LocalizedStrict id="weekMenu-no-menu-label">
                    <Typography gutterBottom={true} variant="h6" className={classes.noWeeksMenu}>
                        Unfortunately, no menus have been created this week.
                    </Typography>
                </LocalizedStrict>
                <Player autoplay loop controls={false} src={NoDataAnim} className={classes.media} />
                <LocalizedStrict id="weekMenu-no-menu-subtitle">
                    <Typography variant="body1" className={classes.noWeeksMenuSubtitle}>
                        Here you will be able to see the menu for the upcoming days if available. It is not a shop.
                    </Typography>
                </LocalizedStrict>
            </Grid>
        )
    }

    return (
        <div role="presentation" className={classes.container}>
            <LocalizedStrict id="weekMenu-header-label" attrs={{ title: true }}>
                <ModalHeaderContent
                    title="Menu of the week"
                    onClose={hideWeeksMenuModal}
                    bottomElement={
                        weeksMenu && weeksMenu.length > 0 ? (
                            <div ref={tabsRef} style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                                <Tabs
                                    value={activeTab}
                                    indicatorColor="primary"
                                    className={classes.tabs}
                                    variant="scrollable"
                                    TabIndicatorProps={{ style: { display: "none" } }}
                                >
                                    {weeksMenu.map((tab: IMenu, menuKey: number) => (
                                        <Tab
                                            label={dateTimeFactory.formatDayAndDate(dateTimeFactory.fromISO(tab.date))}
                                            onClick={() => setActiveTab(menuKey)}
                                            className={classes.tab}
                                            key={menuKey}
                                        />
                                    ))}
                                </Tabs>
                            </div>
                        ) : undefined
                    }
                />
            </LocalizedStrict>

            <Box className={clsx(classes.contentWrap, isWeekMenu && classes.contentWrapUpdate)} {...swipeHandlers}>
                {weekMenuElements()}
            </Box>
        </div>
    )
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, hideWeeksMenuModal } = props
    const classes = useStyles()
    const rootAttributes = {
        onClose: hideWeeksMenuModal,
        open: isDrawerOpen,
        className: classes.root,
    }

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideWeeksMenuModal}>
                    <ModalContent {...props} classes={classes} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
