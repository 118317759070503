import React from "react"
import { CardMedia, Typography } from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import { useExternalLinks } from "shared/Modules/Cordova/hooks/useAppLinks"
import { IUserNewsDetails } from "shared/Modules/UserCards/userCardsTypes"
import Card from "shared/Components/Card/Card"

const useStyles = makeStyles((theme) =>
    createStyles({
        newsCard: {
            "& > .MuiPaper-root": {
                boxShadow: "none",
            },
        },
        newsCardTitle: {
            fontSize: theme.typography.body1.fontSize,
            margin: theme.spacing(1.2, 0, 0),
        },
        newsCardMedia: {
            width: "100%",
            aspectRatio: "16/9",
        },
    })
)

type UserNewsCardProps = IUserNewsDetails

export function UserNewsCard(props: UserNewsCardProps) {
    const { content } = props
    const { imageUrl, subject, permaLink } = content
    const classes = useStyles({})

    const openExternalLink = useExternalLinks()
    const handleLinkClick = (url: string) => {
        openExternalLink(url)
    }
    return (
        <Card
            className={classes.newsCard}
            onClick={() => {
                handleLinkClick(permaLink ?? "")
            }}
            width="100%"
        >
            {imageUrl && (
                <CardMedia
                    component="img"
                    alt={subject}
                    style={{ borderRadius: 5 }}
                    image={imageUrl}
                    title={subject}
                    height={130}
                    className={classes.newsCardMedia}
                />
            )}
            <Typography variant="h4" className={classes.newsCardTitle}>
                {subject}
            </Typography>
        </Card>
    )
}
