import React from "react"
import { IUserSurveyDetails, UserCard, UserCards } from "../userCardsTypes"
import { LunchSubscriptionCard } from "./components/LunchSubscriptionCard"
import { CheckInReminderCard } from "./components/CheckInReminderCard"
import { SummaryNumberCard } from "./components/SummaryNumberCard"
import { ExternalLinkCard } from "./components/ExternalLinkCard"
import { UserSurveyCard } from "./components/UserSurveyCard"
import { UserNewsCard } from "./components/UserNewsCard"

type UserCardFactoryProps = {
    card: UserCard
    showSurveyModal: (survey: IUserSurveyDetails["content"]) => void
}

export function UserCardFactory(props: UserCardFactoryProps) {
    const { card, showSurveyModal } = props

    switch (card.type) {
        case UserCards.CHECK_IN_REMINDER:
            return <CheckInReminderCard {...card.checkInReminderDetails} />
        case UserCards.USER_SURVEY:
            return <UserSurveyCard card={card} showSurveyModal={showSurveyModal} />
        case UserCards.SUMMARY_NUMBER:
            return <SummaryNumberCard {...card.summaryNumberDetails} />
        case UserCards.LUNCH_SUBSCRIPTION:
            return <LunchSubscriptionCard {...card.lunchSubscriptionDetails} />
        case UserCards.EXTERNAL_LINK:
            return <ExternalLinkCard {...card.externalLinkDetails} />
        case UserCards.NEWS_ITEM:
            return <UserNewsCard {...card.newsItemDetails} />
        default:
            return null
    }
}
