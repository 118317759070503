import React, { useCallback } from "react"
import clsx from "clsx"
import { useDispatch } from "react-redux"
import { faTimes } from "@fortawesome/pro-light-svg-icons"
import { Grid, IconButton, Typography } from "@material-ui/core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import { faShoppingBasket } from "@fortawesome/pro-solid-svg-icons"
import { IUserSurveyCard, IUserSurveyCardColors, IUserSurveyDetails } from "shared/Modules/UserCards/userCardsTypes"
import SurveyCardBackground from "shared/Modules/Surveys/Assets/smiley_survey_bg.svg"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { hideUserCard } from "../../userCardsActions"
import AssetHelper from "shared/Helpers/AssetHelper"
import Card from "shared/Components/Card/Card"

type IClassesProps = {
    userSurvey?: IUserSurveyCardColors
}

const useStyles = makeStyles((theme) =>
    createStyles({
        userSurveyCardContent: ({ userSurvey }: IClassesProps) => ({
            backgroundColor: userSurvey?.background || theme.palette.common.white,
            padding: "1rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }),
        userSurveyCardTitle: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.header || theme.palette.primary.main,
            fontSize: "1.125rem",
            fontWeight: 700,
        }),
        userSurveyCloseButton: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.closeIcon || theme.palette.primary.main,
            height: theme.spacing(2.5),
            width: theme.spacing(2.5),
        }),
        userSurveyBasketIcon: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.basketIcon || theme.palette.text.secondary,
            marginRight: theme.spacing(1),
        }),
        userSurveyProductName: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.productName || theme.palette.text.primary,
            height: "4em",
            fontWeight: 400,
            lineHeight: 1.3,
            display: "-webkit-box",
            overflow: "hidden",
            textOverflow: "ellipsis",
            WebkitLineClamp: 3,
            WebkitBoxOrient: "vertical",
        }),
        userSurveyKitchenName: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.kitchenName || theme.palette.text.secondary,
            marginTop: "0.5rem",
        }),
        userSurveyCardBg: {
            backgroundImage: `url(${AssetHelper.url(SurveyCardBackground)})`,
            backgroundPosition: `center right`,
            backgroundRepeat: "no-repeat",
            backgroundOrigin: "content-box",
            backgroundSize: 100,
            backgroundBlendMode: "color-burn",
        },
        userSurveyProductBlock: {
            flex: "1 1 auto",
            margin: "0.5rem 0",
        },
        userSurveyRateButtonBlock: {
            flex: "0 0 auto",
            alignSelf: "flex-end",
            width: "100px",
        },
        userSurveyRateButton: ({ userSurvey }: IClassesProps) => ({
            color: userSurvey?.action || theme.palette.primary.main,
            filter: "opacity(0.8)",
        }),
        cardHeading: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
        },
    })
)

type UserSurveyCardProps = Readonly<{
    card: IUserSurveyCard
    showSurveyModal: (survey: IUserSurveyDetails["content"]) => void
}>

export function UserSurveyCard({ card: { id, surveyDetails }, showSurveyModal }: UserSurveyCardProps) {
    const { content, colors } = surveyDetails
    const { header, product, kitchen } = content
    const dispatch = useDispatch()

    const classes = useStyles({
        userSurvey: colors ?? {},
    })

    function handleSurveyDismiss(event: React.MouseEvent<HTMLButtonElement>) {
        event.stopPropagation()
        dispatch(hideUserCard(id))
    }

    const handleSurveyOpen = useCallback(() => showSurveyModal(content), [showSurveyModal, content])

    return (
        <>
            <Card width="100%" onClick={handleSurveyOpen}>
                <div className={clsx(classes.userSurveyCardContent, classes.userSurveyCardBg)}>
                    <div className={classes.cardHeading}>
                        <Typography variant="h4" className={classes.userSurveyCardTitle}>
                            {header}
                        </Typography>
                        <IconButton
                            aria-label="close"
                            size="small"
                            className={classes.userSurveyCloseButton}
                            onClick={handleSurveyDismiss}
                        >
                            <FontAwesomeIcon icon={faTimes} />
                        </IconButton>
                    </div>
                    <Typography variant="body2" className={classes.userSurveyKitchenName}>
                        {kitchen.name}
                    </Typography>

                    <Grid container wrap="nowrap" justifyContent="space-between" alignItems="flex-start">
                        <Grid
                            item
                            container
                            wrap="nowrap"
                            alignItems="baseline"
                            className={classes.userSurveyProductBlock}
                        >
                            <Grid item>
                                <FontAwesomeIcon className={classes.userSurveyBasketIcon} icon={faShoppingBasket} />
                            </Grid>
                            <Grid item>
                                <Typography variant="body1" className={classes.userSurveyProductName}>
                                    {product.name}
                                </Typography>
                            </Grid>
                        </Grid>
                        <Grid item className={classes.userSurveyRateButtonBlock}>
                            <LocalizedStrict id="home-user-card-survey-rate-now">
                                <Typography align="right" className={classes.userSurveyRateButton} variant="body2">
                                    Rate now
                                </Typography>
                            </LocalizedStrict>
                        </Grid>
                    </Grid>
                </div>
            </Card>
        </>
    )
}
