import React from "react"
import { CardMedia, Typography } from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import {
    IExternalLinkDetails,
    ILunchSubscriptionCardColors,
    ILunchSubscriptionDetails,
} from "shared/Modules/UserCards/userCardsTypes"
import Card from "shared/Components/Card/Card"
import { useExternalLinks } from "shared/Modules/Cordova/hooks/useAppLinks"

type IClassesProps = {
    lunchSubscription?: ILunchSubscriptionCardColors
}

const useStyles = makeStyles((theme) =>
    createStyles({
        lunchSubscriptionHeadline: ({ lunchSubscription }: IClassesProps) => ({
            color: lunchSubscription?.displayName || theme.palette.text.primary || "#FFFFFFFF",
            fontSize: "1.125rem",
            fontWeight: 700,
            margin: 0,
            marginRight: "1rem",
        }),
        lunchSubscriptionMerchantName: ({ lunchSubscription }: IClassesProps) => ({
            color: lunchSubscription?.merchantDisplayName || theme.palette.text.secondary || "#969696FF",
            fontSize: "0.875rem",
            marginTop: "0.5rem",
        }),
        relative: {
            position: "relative",
        },
        pointer: {
            cursor: "pointer",
        },
        externalLinkCardContent: {
            paddingTop: "1rem",
            paddingLeft: "1rem",
        },
    })
)

type ExternalLinkCardProps = IExternalLinkDetails

export function ExternalLinkCard(props: ExternalLinkCardProps) {
    const { url, headline, image, description } = props.content
    const classes = useStyles({})

    const openExternalLink = useExternalLinks()
    const handleExternalLinkClick = (url: string) => {
        if (url) {
            openExternalLink(url)
        }
    }

    return (
        <Card
            onClick={() => {
                handleExternalLinkClick(url ?? "")
            }}
            width="100%"
            className={classes.pointer}
        >
            {image && (
                <div className={classes.relative}>
                    <CardMedia
                        component="img"
                        alt={headline}
                        height="172"
                        style={{ borderRadius: 5 }}
                        image={image}
                        title={headline}
                    />
                </div>
            )}
            <div className={classes.externalLinkCardContent}>
                <Typography variant="h4" className={classes.lunchSubscriptionHeadline}>
                    {headline}
                </Typography>
                <Typography variant="body2" className={classes.lunchSubscriptionMerchantName}>
                    {description}
                </Typography>
            </div>
        </Card>
    )
}
