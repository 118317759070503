import React, { useMemo, useState } from "react"
import { Dialog, Grid, makeStyles, Typography } from "@material-ui/core"
import Screen from "shared/Components/Skeleton/Screen"
import { Localized } from "@fluent/react"
import { useToken } from "shared/Modules/Login/useToken"
import { useApiCall } from "shared/Modules/Query/useApiCall"
import { useQuery } from "shared/Modules/Query/useQuery"
import { IFoodOrderProduct } from "shared/Modules/Product/productTypes"
import { getProductFavorites } from "mobile/Api/GetProductFavorites"
import { getProductFavorites as sagaGetProductFavorites } from "mobile/Actions/canteenActions"
import OptionsDialog from "shared/Components/Dialog/Options/OptionsDialog"
import { ActionSheetButtonItem } from "shared/Components/Dialog/Options/ActionSheetModalContent"
import removeProductFromFavorites from "../Api/RemoveProductToFavorites"
import { useDispatch } from "react-redux"
import { FavoriteResourceCard } from "../Screens/Home/components/FavoriteRecourseCard"

interface IProps {
    open: boolean
    onFavoriteClick: (favorite: IFoodOrderProduct) => void
    onClose: () => void
}

const useStyles = makeStyles(() => ({
    backgroundClass: {
        backgroundColor: "#F1F5F9",
    },
    cardsWrapper: {
        gap: "2rem",
        paddingTop: "1.5rem",
        paddingBottom: "1.5rem",
    },
}))

export default function ShowAllProductFavoritesModal(props: IProps) {
    const classes = useStyles()
    const token = useToken()
    const dispatch = useDispatch()
    const { QueryPane, response, refresh } = useQuery(() => getProductFavorites(token), [token, props.open], props.open)
    const {
        loading: isRemovingFavorite,
        callForAction: callRemoveFavorite,
        handleCallError,
    } = useApiCall(removeProductFromFavorites)
    const [chosenFavoriteId, setChosenFavoriteId] = useState<number | undefined>()

    function removeFromFavorite(id?: number) {
        setChosenFavoriteId(undefined)
        if (!id) return
        if (response.loading || response.failed) return
        const details = response.response.data.products.find((product) => product.id === id)?.userDetails
        if (details && "favoriteType" in details) {
            callRemoveFavorite(id, details.favoriteType, token)
                .then(() => {
                    refresh()
                    dispatch(sagaGetProductFavorites())
                })
                .catch((e) => handleCallError(e, "removing favorite"))
        }
    }

    function openRemoveFavoriteDialog(e: any, productId: number) {
        e.stopPropagation()
        setChosenFavoriteId(productId)
    }

    const cards = useMemo(() => {
        if (!response.loading && !response.failed) {
            if (response.response.data.products.length === 0)
                return (
                    <Localized id="home-all-product-favorites-empty">
                        <Typography>Du har ingen favoritter.</Typography>
                    </Localized>
                )
            return (
                response.response.data.products.map((shop, index) => (
                    <Grid item key={index}>
                        <FavoriteResourceCard
                            resource={shop}
                            onClick={props.onFavoriteClick}
                            resourceTitleProp="name"
                            resourceImageProp="imageUrl"
                            onCardAction={openRemoveFavoriteDialog}
                        />
                    </Grid>
                )) ?? []
            )
        } else return []
    }, [response.loading, props.onFavoriteClick])

    return (
        <Dialog classes={{ paper: classes.backgroundClass }} open={props.open} fullScreen>
            <Localized id="home-all-product-favorites-title" attrs={{ primaryTopBarTitle: true }}>
                <Screen
                    backgroundClass={classes.backgroundClass}
                    name="myFavoritesModal"
                    showPrimaryTopBar
                    fitPage
                    onBackButtonPress={props.onClose}
                    backButtonIcon="cross"
                    primaryTopBarTitle="Mine favoritter"
                >
                    <QueryPane centerVertical>
                        {(data) => (
                            <Grid container className={classes.cardsWrapper} direction="column" wrap="nowrap">
                                {cards}
                            </Grid>
                        )}
                    </QueryPane>
                    <Localized id="home-product-favorites-options" attrs={{ loadingDescription: true }}>
                        <OptionsDialog
                            open={chosenFavoriteId !== undefined}
                            onClose={() => setChosenFavoriteId(undefined)}
                            loading={isRemovingFavorite}
                            loadingDescription="Fjerner favorit..."
                        >
                            <Localized id="home-remove-favorite">
                                <ActionSheetButtonItem
                                    color="error"
                                    onClick={() => removeFromFavorite(chosenFavoriteId)}
                                >
                                    Fjern favorit
                                </ActionSheetButtonItem>
                            </Localized>
                        </OptionsDialog>
                    </Localized>
                </Screen>
            </Localized>
        </Dialog>
    )
}
