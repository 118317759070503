import { Localized } from "@fluent/react"
import React, { useCallback, useEffect } from "react"
import { MapStateToProps, MapDispatchToPropsFunction, connect } from "react-redux"
import { CardContent, CardMedia, createStyles, Drawer, Grid, makeStyles, Theme, Typography } from "@material-ui/core"
import { Roboto } from "shared/theme"
import { IStoreState } from "../Reducers/rootReducer"
import ModalHeader from "./ModalHeader"
import Card from "shared/Components/Card/Card"
import { closeAllNewsModal, getNews, ICloseAllNewsModal, IGetNews } from "shared/Modules/News/newsActions"
import { INews } from "shared/Types/appTypes"
import { IOpenNewsDetailsModal, openNewsDetailsModal } from "shared/Modules/News/newsActions"
import ErrorHandlerModal from "shared/Modules/Error/Components/ErrorHandlerModal"
import { INewsState } from "shared/Modules/News/newsReducer"
import { Logger } from "shared/Helpers/logging"
import { useExternalLinks } from "shared/Modules/Cordova/hooks/useAppLinks"
import { CardDescription } from "shared/Components/Skeleton/CardDescription"
import { CardTitle } from "shared/Components/Skeleton/CardTitle"

// These are the props that can/should be passed from a parent component
// It is the only exported prop interface since all other props are internal
export interface IOwnProps {
    // This component does not have own props
}

// These are the props we expect to be passed from Redux state
interface IStateProps {
    isDrawerOpen: boolean
    news: INewsState
    selectedCategory: keyof INewsState | null
}

// These are the props used to dispatch actions from the component
interface IDispatchProps {
    hideAllNewsModal: () => ICloseAllNewsModal
    getAllNews: () => IGetNews
    showNews: (news: INews) => IOpenNewsDetailsModal
}

type IProps = IOwnProps & IStateProps & IDispatchProps

const mapStateToProps: MapStateToProps<IStateProps, IOwnProps, IStoreState> = ({ allNewsModal, news }) => ({
    isDrawerOpen: allNewsModal.open,
    news: news,
    selectedCategory: allNewsModal.category,
})

const mapDispatchToProps: MapDispatchToPropsFunction<IDispatchProps, IOwnProps> = (dispatch) => ({
    hideAllNewsModal: () => dispatch(closeAllNewsModal()),
    getAllNews: () => {
        return dispatch(getNews())
    },
    showNews: (news: INews) => {
        return dispatch(openNewsDetailsModal(news))
    },
})

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            minHeight: "100%",
            overflow: "auto",
            padding: ".625rem 1rem 2rem 1rem",
            width: "100vw",
            backgroundColor: "#F1F5F9",
        },
        newsContainer: {
            paddingTop: "2rem",
            gap: "2rem",
        },
        productCardContent: {
            padding: "1.5rem 1rem",
        },
        relative: {
            position: "relative",
        },
        imageLabel: {
            position: "absolute",
            top: 0,
            left: 15,
            color: "#FFFFFF",
            fontFamily: Roboto,
            fontSize: theme.typography.body2.fontSize,
            letterSpacing: -0.25,
            borderRadius: 11,
            backgroundColor: "rgba(0,0,0,0.31)",
            padding: "4px 10px",
        },
        cardContainer: {
            width: "100%",
        },
        root: {
            position: "fixed",
        },
        noNews: {
            color: "#505050",
            fontFamily: Roboto,
            fontSize: theme.typography.body1.fontSize,
            fontWeight: 400,
            letterSpacing: 0,
            lineHeight: "19px",
        },
    })
)

const ModalContent = (props: IProps & { classes: ReturnType<typeof useStyles> }) => {
    const { hideAllNewsModal, getAllNews, news, selectedCategory, showNews } = props

    const classes = useStyles()
    const openExternalLink = useExternalLinks()

    useEffect(() => {
        // Fetch news
        if (!selectedCategory || news[selectedCategory] === undefined) {
            getAllNews()
        }
    })

    const allNews = selectedCategory ? news?.[selectedCategory] : undefined
    const logger = new Logger("news-list")

    const onNewsStoryClick = useCallback(
        (resource: any) => {
            const story = resource as INews
            if (story.external && story.permaLink) {
                logger.info("News story has permaLink [open as external link]")
                openExternalLink(story.permaLink)
            } else {
                logger.info("News story does not have permaLink [show in news details page]")
                showNews(story)
            }
        },
        [openExternalLink, showNews]
    )

    const newsElements = () => {
        if (allNews && allNews.length > 0) {
            return allNews.map((newsItem: INews, newsKey: number) => {
                const headerTag = () => {
                    if (!newsItem.category) {
                        return <div />
                    }

                    return (
                        <p
                            style={{
                                position: "absolute",
                                top: 0,
                                left: 15,
                                color: "#FFFFFF",
                                fontFamily: Roboto,
                                fontSize: "0.875rem", // 14px @ std
                                letterSpacing: -0.25,
                                borderRadius: 11,
                                backgroundColor: "rgba(0,0,0,0.31)",
                                padding: "4px 10px",
                            }}
                        >
                            {newsItem.category}
                        </p>
                    )
                }

                return (
                    <div className={classes.cardContainer} key={newsKey} onClick={() => onNewsStoryClick(newsItem)}>
                        <Card boxShadow="none" height={"100%"} width={"100%"}>
                            <div className={classes.relative}>
                                <CardMedia
                                    component="img"
                                    alt={newsItem.subject}
                                    height="172"
                                    image={newsItem.imageUrl}
                                    title={newsItem.subject}
                                />
                                {headerTag()}
                            </div>
                            <CardContent className={classes.productCardContent}>
                                <CardTitle>{newsItem.subject}</CardTitle>
                                <CardDescription>{newsItem.createdByCompany}</CardDescription>
                            </CardContent>
                        </Card>
                    </div>
                )
            })
        }

        if (allNews === null) {
            return <div />
        }

        return (
            <div>
                <Card width="100%">
                    <CardContent>
                        <Localized id="allNews-noNews-label">
                            <Typography gutterBottom={true} variant="h5" component="h3" className={classes.noNews}>
                                No news
                            </Typography>
                        </Localized>
                    </CardContent>
                </Card>
            </div>
        )
    }

    if (selectedCategory === null) return null

    return (
        <div role="presentation" className={classes.container}>
            <Localized id="allNews-header-label" attrs={{ title: true }}>
                <ModalHeader fixed={true} title="News" onClose={hideAllNewsModal} />
            </Localized>
            <Grid container className={classes.newsContainer} direction="column" wrap="nowrap">
                {newsElements()}
            </Grid>
        </div>
    )
}

const Modal = (props: IProps) => {
    const { isDrawerOpen, selectedCategory, hideAllNewsModal } = props
    const classes = useStyles()
    const rootAttributes = {
        onClose: hideAllNewsModal,
        open: selectedCategory !== null && isDrawerOpen,
        className: classes.root,
    }

    return (
        <div>
            <Drawer anchor="right" {...rootAttributes}>
                <ErrorHandlerModal close={hideAllNewsModal}>
                    <ModalContent {...props} classes={classes} />
                </ErrorHandlerModal>
            </Drawer>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(Modal)
