import React from "react"
import { createStyles, makeStyles } from "@material-ui/core/styles"
import { UserCard, IUserCardGroup, IUserCardsSection } from "shared/Modules/UserCards/userCardsTypes"
import { useUserSurveyModal } from "shared/Modules/Surveys/userSurveyHooks"
import { Section } from "shared/Components/Skeleton/Section"
import CardCarousel from "mobile/Components/CardCarousel"
import { UserCardFactory } from "./UserCardFactory"

const useStyles = makeStyles(() =>
    createStyles({
        cardCarousel: {
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
        },
    })
)

interface IUserCardSectionsProps {
    sections: IUserCardsSection[]
    containerClassName?: string
}

export function UserCardSections(props: IUserCardSectionsProps) {
    const { sections, containerClassName } = props
    const classes = useStyles({})
    const { UserSurveyModalAdapter, showSurveyModal } = useUserSurveyModal()

    return sections.length ? (
        <>
            {sections.map((section: IUserCardsSection) => {
                return (
                    <Section key={section.id} title={section.title} className={containerClassName}>
                        <div className={classes.cardCarousel}>
                            {section.groups.map((group: IUserCardGroup, index: number) => {
                                return (
                                    <CardCarousel key={`group_${index}`}>
                                        {group.cards.map((card: UserCard) => {
                                            return (
                                                <UserCardFactory
                                                    key={card.id}
                                                    card={card}
                                                    showSurveyModal={showSurveyModal}
                                                />
                                            )
                                        })}
                                    </CardCarousel>
                                )
                            })}
                        </div>
                    </Section>
                )
            })}
            <UserSurveyModalAdapter />
        </>
    ) : null
}

export default React.memo(UserCardSections)
