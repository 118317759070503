import React from "react"
import { Typography } from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import { ILunchSubscriptionCardColors, ILunchSubscriptionDetails } from "shared/Modules/UserCards/userCardsTypes"
import Card from "shared/Components/Card/Card"

type IClassesProps = {
    lunchSubscription?: ILunchSubscriptionCardColors
}

const useStyles = makeStyles((theme) =>
    createStyles({
        lunchSubscriptionCardContent: ({ lunchSubscription }: IClassesProps) => ({
            backgroundColor: lunchSubscription?.background || "#FFFFFFFF",
            padding: "1rem",
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        }),
        lunchSubscriptionHeaderWrapper: {
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
        },
        lunchSubscriptionHeadline: ({ lunchSubscription }: IClassesProps) => ({
            color: lunchSubscription?.displayName || theme.palette.text.primary || "#FFFFFFFF",
            fontSize: "1.125rem",
            fontWeight: 700,
            margin: 0,
            marginRight: "1rem",
        }),
        statusLabel: ({ lunchSubscription }: IClassesProps) => ({
            backgroundColor: lunchSubscription?.statusLabelBackground || theme.palette.common.white || "#969696FF",
            color: lunchSubscription?.statusLabel || theme.palette.common.black || "#969696FF",
            borderRadius: "0.25rem",
            padding: "0.25rem 0.5rem",
            fontSize: "0.75rem",
        }),
        lunchSubscriptionMerchantName: ({ lunchSubscription }: IClassesProps) => ({
            color: lunchSubscription?.merchantDisplayName || theme.palette.text.secondary || "#969696FF",
            fontSize: "0.875rem",
            marginTop: "0.5rem",
        }),
        lunchSubscriptionDateWrapper: {
            fontSize: "0.75rem",
        },
        lunchSubscriptionDateLabel: ({ lunchSubscription }: IClassesProps) => ({
            color: lunchSubscription?.subscriptionDateLabel || theme.palette.text.secondary || "#969696FF",
        }),
    })
)

type LunchSubscriptionCardProps = ILunchSubscriptionDetails

export function LunchSubscriptionCard(props: LunchSubscriptionCardProps) {
    const { displayName, statusLabel, merchantDisplayName, subscriptionDate, subscriptionDateLabel } = props.content
    const classes = useStyles({
        lunchSubscription: props.colors ?? {},
    })

    return (
        <Card height="120px" width="100%">
            <div className={classes.lunchSubscriptionCardContent}>
                <div>
                    <div className={classes.lunchSubscriptionHeaderWrapper}>
                        <Typography variant="h4" className={classes.lunchSubscriptionHeadline}>
                            {displayName}
                        </Typography>
                        <div className={classes.statusLabel}>{statusLabel}</div>
                    </div>
                    <Typography variant="body2" className={classes.lunchSubscriptionMerchantName}>
                        {merchantDisplayName}
                    </Typography>
                </div>

                <div className={classes.lunchSubscriptionDateWrapper}>
                    <span className={classes.lunchSubscriptionDateLabel}>{subscriptionDateLabel}</span>{" "}
                    {subscriptionDate}
                </div>
            </div>
        </Card>
    )
}
