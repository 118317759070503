import React from "react"
import { Grid, Typography } from "@material-ui/core"
import { makeStyles, createStyles } from "@material-ui/core/styles"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPartyHorn, faCircleExclamation, faArrowRight } from "@fortawesome/pro-solid-svg-icons"
import { LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { ICheckInReminderDetails } from "shared/Modules/UserCards/userCardsTypes"
import Card from "shared/Components/Card/Card"

const useStyles = makeStyles(() =>
    createStyles({
        checkInReminderCardContent: ({ isCheckedIn }: { isCheckedIn: boolean }) => ({
            flexDirection: "column",
            alignItems: "flex-start",
            gap: ".625rem",
            padding: "1rem",
            background: isCheckedIn ? "#86EFAC" : "#FCA5A5",
            color: isCheckedIn ? "#14532D" : "#7F1D1D",
        }),
        checkInReminderCardContentBox: {
            gap: ".625rem",
            justifyContent: "flex-end",
            alignItems: "center",
        },
    })
)

type CheckInReminderCardProps = ICheckInReminderDetails

export function CheckInReminderCard(props: CheckInReminderCardProps) {
    const { isCheckedIn } = props.content
    const classes = useStyles({ isCheckedIn })

    return (
        <Card width="100%">
            <Grid container className={classes.checkInReminderCardContent}>
                <FontAwesomeIcon size="3x" icon={isCheckedIn ? faPartyHorn : faCircleExclamation} />
                <LocalizedStrict
                    id={
                        isCheckedIn
                            ? "home-user-card-check-in-reminder-check-in"
                            : "home-user-card-check-in-reminder-no-check-in"
                    }
                >
                    <Typography variant="h4">
                        {isCheckedIn
                            ? "Please remember to check into the canteen today"
                            : "Please remember to check into the canteen today"}{" "}
                    </Typography>
                </LocalizedStrict>
                <Grid container className={classes.checkInReminderCardContentBox}>
                    <LocalizedStrict id="home-user-card-check-in-reminder-learn-more">
                        <Typography variant="body2">Learn more</Typography>
                    </LocalizedStrict>
                    <FontAwesomeIcon icon={faArrowRight} />
                </Grid>
            </Grid>
        </Card>
    )
}
