import React, { FormEvent, useEffect, useMemo, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { push } from "connected-react-router"
import { List, Theme, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/styles"
import { Logger } from "shared/Helpers/logging"
import { LocalizedEnum, LocalizedStrict } from "shared/Modules/Localization/Components/Localized"
import { useAppErrorHandling } from "shared/Modules/Error/errorHooks"
import { CompanyChoice, SelectCompanyReason } from "../loginTypes"
import {
    selectCompanyChoices,
    selectCompanyDuplicateValue,
    selectCompanyReason,
    selectIsLoading,
    selectIsRequiresSelectCompany,
    selectUsername,
} from "../loginSelectors"
import { selectCompany } from "../loginActions"
import {
    FormActionArea,
    FormHeader,
    FormInputArea,
    FormLocationListItem,
    FormParagraph,
    LoginForm,
    LoginScreen,
    SubmitButton,
} from "../Components/LoginForm"
import { groupBy } from "lodash"

type SelectCompanyProps = Readonly<{
    mode: "explanation" | "list"
}>

function matches(x: CompanyChoice, y: CompanyChoice | undefined) {
    if (y === undefined) return false

    if (x.user?.id && y.user?.id) {
        return x.user.id === y.user.id
    }

    return x.uid === y.uid && x.user?.userGroup?.id === y.user?.userGroup?.id
}

const useStyles = makeStyles((theme: Theme) => ({
    duplicateValue: {
        color: "#3c8530",
        fontWeight: theme.typography.fontWeightBold,
    },
    companyList: {
        paddingTop: theme.spacing(3),
    },
    companiesWrapper: {
        marginBottom: theme.spacing(6),
    },
}))

export function SelectCompany({ mode }: SelectCompanyProps) {
    const [companyChoice, setCompanyChoice] = useState<CompanyChoice>()

    const isRequiresSelectCompany = useSelector(selectIsRequiresSelectCompany)
    const username = useSelector(selectUsername)
    const companies = useSelector(selectCompanyChoices)
    const reason = useSelector(selectCompanyReason)
    const duplicateValue = useSelector(selectCompanyDuplicateValue) ?? ""
    const loading = useSelector(selectIsLoading)
    const dispatch = useDispatch()

    const classes = useStyles()
    const { dispatchInternalError } = useAppErrorHandling()

    const logger = new Logger("select-company")
    const definiteReason = reason ?? SelectCompanyReason.MULTIPLE_COMPANIES_FOR_DOMAIN
    const isAccountSelection = definiteReason === SelectCompanyReason.MULTIPLE_ACCOUNTS_FOR_USERNAME

    useEffect(() => {
        // Only validate response if it is the response corresponding to this page
        // Due to the way our login flow works the response will change before we navigate to the next page making it necessary to have this check
        if (isRequiresSelectCompany) {
            if (!companies || companies.length === 0)
                dispatchInternalError(
                    "API did not return companies to choose from. Please contact support to resolve the issue.",
                )
            if (!reason) {
                logger.warn(`API did not return reason for selecting company [assume multiple accounts]`)
            }
        }
    }, [companies, reason])

    const groupedCompanies = useMemo(() => {
        if (isAccountSelection) {
            const grouped = groupBy(companies, (choice) => choice.uid ?? "Unknown Company UID")
            return Object.entries(grouped).map(([uid, accounts]) => ({
                companyUid: uid,
                companyName: accounts[0]?.name ?? "Unknown Name",
                companyAddress:
                    `${accounts[0].address?.streetName ?? ""} ${accounts[0].address?.streetNumber ?? ""}`.trim() ??
                    "Unknown Address",
                accounts,
            }))
        }
        return null
    }, [isAccountSelection, companies])

    function handleSubmit(e: FormEvent<HTMLFormElement>) {
        e.preventDefault()
        if (mode === "explanation") dispatch(push("/login/select-company-list"))
        else if (username && companyChoice) dispatch(selectCompany(username, companyChoice, () => {}))
    }

    return (
        <LoginScreen backButtonNavigatesTo={mode === "explanation" ? "previous" : "previous-preserve-state"}>
            <LoginForm onSubmit={handleSubmit}>
                <LocalizedEnum base="authenticate-select-company-header-reason" enumValue={definiteReason}>
                    <FormHeader>Vælg firma</FormHeader>
                </LocalizedEnum>
                <FormInputArea>
                    {mode === "explanation" ? (
                        <LocalizedEnum
                            base="authenticate-select-company-reason-message"
                            enumValue={definiteReason}
                            vars={{ duplicateValue }}
                            elems={{ duplicateValue: <span className={classes.duplicateValue}></span> }}
                        >
                            <FormParagraph>
                                Vi kunne ikke automatisk finde dit firma / enhed, da dit email domæne facilitynet.dk er
                                tilknyttet flere enheder.
                            </FormParagraph>
                        </LocalizedEnum>
                    ) : (
                        <>
                            <LocalizedEnum base="authenticate-select-company-action-message" enumValue={definiteReason}>
                                <FormParagraph>Vælg firma / lokation, som du ønsker at logge ind hos:</FormParagraph>
                            </LocalizedEnum>
                            <List className={classes.companyList}>
                                {isAccountSelection && groupedCompanies
                                    ? groupedCompanies.map((group) => (
                                          <div className={classes.companiesWrapper} key={group.companyUid}>
                                              <FormParagraph>{group.companyName}</FormParagraph>
                                              <Typography color="textSecondary">{group.companyAddress}</Typography>
                                              {group.accounts.map((choice, index) => (
                                                  <FormLocationListItem
                                                      key={index}
                                                      company={choice}
                                                      isAccountSelection={isAccountSelection}
                                                      checked={matches(choice, companyChoice)}
                                                      onClick={() => setCompanyChoice(choice)}
                                                  />
                                              ))}
                                          </div>
                                      ))
                                    : companies?.map((choice, index) => (
                                          <FormLocationListItem
                                              key={index}
                                              company={choice}
                                              isAccountSelection={isAccountSelection}
                                              checked={matches(choice, companyChoice)}
                                              onClick={() => setCompanyChoice(choice)}
                                          />
                                      ))}
                            </List>
                        </>
                    )}
                </FormInputArea>
                <FormActionArea>
                    <LocalizedStrict id="authenticate-select-company-submit-button" attrs={{ loadingLabel: true }}>
                        <SubmitButton
                            disabled={mode === "list" && !companyChoice}
                            loading={loading}
                            loadingLabel="Gemmer..."
                        >
                            Næste
                        </SubmitButton>
                    </LocalizedStrict>
                </FormActionArea>
            </LoginForm>
        </LoginScreen>
    )
}
